  <template>
  <!-- element组件库的菜单标签 -->
    <el-menu
       style="width: 250px;border-right: none"
       class="el-menu-vertical-demo"
       :router="true"
       background-color="#fff"
       text-color="#444"
       active-text-color="#111"
    >
      <!-- 一级路由 -->
      <template v-for="item in filterRoutes">
            <el-menu-item  :index="item.path" v-if="item.children?.length===1" :key="item.children.path">
              <i :class="getTitle(item).icon" />
              <span>{{getTitle(item).title}}</span>
          </el-menu-item>
          <!-- 带二级路由的一级 -->
           <el-submenu :index="item.path" v-if="item.children?.length>1" :key="item.children.path">
            <template slot="title">
                <i :class="getTitle(item).icon" />
                <span>{{getTitle(item).title}}</span>
            </template>
            <!-- 展开后得到的二级路由 -->
            <el-menu-item-group  v-for="item1 in item.children" :key="item1.path">
               <el-menu-item :index="item1.path"  v-if="!item1.children">
                  <div>
                    <i :class="getTitle(item1).icon" />
                    <span>{{getTitle(item1).title}}</span>
                  </div>
               </el-menu-item>
                <!-- 带三级路由的二级 -->
                 <el-submenu v-if="item1.children" index="">
                    <div slot="title">
                       <i :class="getTitle(item1).icon" />
                       <span>{{getTitle(item1).title}}</span>
                    </div>
                    <!-- 展开后得到的三级路由 -->
                    <el-menu-item :index="item2.path" v-for="item2 in item1.children" :key="item2.path">
                         <div>
                           <i :class="getTitle(item2).icon" />
                           <span>{{getTitle(item2).title}}</span>
                         </div>
                    </el-menu-item>
                 </el-submenu>
                <!--  -->
            </el-menu-item-group>
          </el-submenu>
      </template>
    </el-menu>
  </template>
<script>
import router from '@/router'
export default {
  data () {
    return {
      filterRoutes: [] // 初始化没隐藏的路由数组
    }
  },
  methods: {
    // 拿到二级路由格式，meta里的icon和title
    getTitle (item) {
      return item.meta
    }
  },
  mounted () {
    // console.log(router.options.routes)
    // 筛选出没有隐藏的路由
    this.filterRoutes = router.options.routes.filter((item) => !item.hidden)
    // console.log(this.filterRoutes)
  }
}
</script>

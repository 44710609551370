<template>

  <div class="common-layout">
    <el-container>
      <el-aside :style="{ width: collapsed ? '252px' : '80px', borderRight:'1px solid #efefef', boxShadow:'1px 0px 1px #efefef' }" class="aside">
        <div class="logo">
          <el-avatar icon="el-icon-user-solid" :src="userInfo.userHeader"></el-avatar>
          
          <!-- 点击个人信息下拉 -->
          <div>
            <el-dropdown trigger="click" @command="returnBtn">
              <span class="el-dropdown-link" style="cursor: pointer;">
                {{userInfo.nickName||'立即登录'}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item command="link">联系客服</el-dropdown-item> -->
                  <el-dropdown-item command="outLogin">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <!-- 左侧区域 -->
        <SideBar ></SideBar>
      </el-aside>
      <el-container class="right-layout">
        <el-header style="border-bottom: 1px solid #efefef;box-shadow: 1px 0px 1px #efefef;">
          <!-- 头部区域 -->
          <NavBar class="el-header" @changeCollapsed="changeCollapsed"></NavBar>
        </el-header>
        <el-main>
          <!-- 主区域 -->
          <!-- 路由容器占位符 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>

</template>

<script>

import SideBar from '@/components/SideBar.vue'
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'layout-index',
  components: {
    SideBar,
    NavBar
  },
  data () {
    return {
      collapsed: true,
      userInfo:{}
    }
  },
  methods: {
    changeCollapsed () {
      this.collapsed = !this.collapsed
      // console.log(this.collapsed)
    },
    returnBtn(item){
      console.log(item)
      if(item=='outLogin'){
         localStorage.setItem('accessToken','')
         localStorage.setItem('userCode','')
         localStorage.setItem('userInfo','')
         localStorage.setItem('IsLogin','1')
        this.$router.replace({path:'/login'})
      }
     
    }
  },
  mounted () {
    let myUserInfo = localStorage.getItem('userInfo')
    if(myUserInfo){
      this.userInfo = JSON.parse(myUserInfo)
    }
   
  }
}
</script>

<style scoped lang="scss">
.common-layout {
  height: 100vh;
}
.el-aside {
  overflow: auto;
  height: 100vh;
  background-color: #fff; //改侧边栏背景色
  color: #fff;
  .logo {
    // height: 60px;
    text-align: center;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    color: #444;
    font-size: 16px;
    text-shadow: 1px 1px 2px #666;
    padding: 15px 0;
  }
}
.right-layout {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  background-color: #f1f3f4; //主区域的背景颜色
  // 顶部header大盒子
  .el-header {
    width: 100%;
    background-color: #fff;
    padding: 0 10px 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.aside {
  transition: 0.4s all;  //收缩速度
}
.el-main {
  padding: 0; //主区域main是否需要padding
}

</style>

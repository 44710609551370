/* eslint-disable eol-last */
/* eslint-disable func-call-spacing */
/* eslint-disable indent */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue' // 引入layout组件

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/home' }, // 重定向到首页
    // 登录规则
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/views/login/LoginPage.vue'),
        hidden: true
    },
    // 首页
    {
        path: '/',
        meta: { title: '文档', icon: 'el-icon-document' },
        component: Layout,
        children: [{
            path: '/home',
            component: () =>
                import ('@/views/document/documentIndex.vue'),
            meta: { title: '文档', icon: 'el-icon-document' }
        }]
    },
    // 银行卡
    {
        path: '/bank',
        meta: { title: '银行卡', icon: 'el-icon-bank-card' },
        component: Layout,
        children: [{
            path: '/bank',
            component: () =>
                import ('@/views/bank/bankIndex.vue'),
            meta: { title: '银行卡', icon: 'el-icon-bank-card' }
        }]
    },
    // 房产
    {
        path: '/realEstate',
        meta: { title: '房产', icon: 'el-icon-office-building' },
        component: Layout,
        children: [{
            path: '/realEstate',
            component: () =>
                import ('@/views/realEstate/realEstateIndex.vue'),
            meta: { title: '房产', icon: 'el-icon-office-building' }
        }]
    },
    // 房产
    {
        path: '/account',
        meta: {
            title: '账号',
            icon: 'el-icon-mobile-phone'
        },
        component: Layout,
        children: [{
            path: '/account',
            component: () =>
                import ('@/views/account/accountIndex.vue'),
            meta: { title: '账号', icon: 'el-icon-mobile-phone' }
        }]
    }

    // Real estate

    // account
    // // 可三级路由（三级需到二级写占位），不用就删除这里的路由配置+views下的TwoRouter文件夹
    // {
    //   path: '/two',
    //   component: Layout,
    //   meta: { title: '二级路由', icon: 'el-icon-user-solid' },
    //   children: [
    //     {
    //       path: '/two/a',
    //       component: () => import('@/views/TwoRouter/TwoChildOne.vue'),
    //       meta: { title: '二级子级1', icon: 'el-icon-user-solid' }
    //     },
    //     {
    //       path: '/two/b',
    //       component: () => import('@/views/TwoRouter/TwoChildTwo.vue'),
    //       meta: { title: '二级子级2', icon: 'el-icon-user-solid' },
    //       children: [
    //         {
    //           path: '/two/b/three',
    //           component: () => import('@/views/TwoRouter/ThreeChildOne.vue'),
    //           meta: { title: '三级路由', icon: 'el-icon-user-solid' }
    //         }
    //       ]
    //     }
    //   ]
    // }
]

const router = new VueRouter({
    routes
})

export default router
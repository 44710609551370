<template>
  <div>
    <!-- 点击图标时出发 -->
    <div></div>
    <!-- <div @click="changeCollapsed">
      <i class="el-icon-s-fold setWidth" v-if="collapsed"></i>
      <i class="el-icon-s-unfold setWidth" v-else></i>
    </div> -->
    <div @click="linkOut">
      <span style="cursor: pointer;">于一科技</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      collapsed: true,
      userInfo:{}
    }
  },
  mounted(){
    let myUserInfo = localStorage.getItem('userInfo')
    if(myUserInfo){
      this.userInfo = JSON.parse(myUserInfo)
    }
  },
  methods: {
    linkOut(){
      const url = 'https://www.yyi.cc/'; // 你想要打开的网址
      window.open(url, '_blank'); // '_blank' 指定在新标签页中打开
    },
    changeCollapsed () {
      this.$emit('changeCollapsed')
      this.collapsed = !this.collapsed
    // console.log(this.collapsed)
    },
    
  }
}
</script>

<style scoped>
/* 收缩图标的大小 */
.setWidth{
    font-size: 20px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/permission' // 路由守卫，需要的时候打开注释
// import 'tinymce/styles/content.min.css';
import { changeBankData, changeAccountData, changeHouseData, getBankDataChange } from './utils/changeData'
// import getBankCardInfo from "bankcardinfo";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$changeBankData = changeBankData
Vue.prototype.$changeAccountData = changeAccountData
Vue.prototype.$changeHouseData = changeHouseData
Vue.prototype.$getBankDataChange = getBankDataChange

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
<template>
   <router-view></router-view>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang='scss'></style>
